@import '../../styles/config';

.ErrorMessage {
  text-align: center;
  color: $danger;
  padding: $spacer * 4 0;
  font-size: $font-size-lg;
}

.ErrorMessage__icon-wrapper {
  margin-bottom: $spacer;
}

.ErrorMessage__footer {
  margin-top: $spacer * .5;
  color: $text-muted;
  font-size: $font-size-sm;
}
