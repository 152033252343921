@import 'src/styles/config';

.NotFoundPage {
  padding: $spacer * 10 0 $spacer * 10;
  text-align: center;
}

.NotFoundPage__title {
  margin-bottom: $spacer;
}

.NotFoundPage__description {
  font-size: $font-size-base * 1.25;
}
